import initSite from '../../utils/js/initSite.mjs'
import headerClass from '../../utils/js/headerClass.mjs'
import hashScroll from '../../utils/js/hashScroll.mjs'
import windowHeight from '../../utils/js/windowHeight.mjs'
import customPropertiesFromWindow from '../../utils/js/customPropertiesFromWindow.mjs'
import inViewClass from '../../utils/js/inViewClass.mjs'


const setup = {
  headerClass: () => headerClass({thresholdOffset: -72}),
  hashScroll,
  windowHeight,
  inViewClass,
  customPropertiesFromWindow //: () => customPropertiesFromWindow({properties: [{scrollTop: '--scroll-top'}]})
}

initSite(setup)